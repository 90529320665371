import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  arrayUnion,
  arrayRemove,
  updateDoc,
  orderBy,
  query,
  limit,
  where,
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  OAuthProvider,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics();
const db = getFirestore();
const auth = getAuth();

// going to grab the chunks of the path for use.
const pathItems = window.location.pathname.split("/");
if (pathItems.length < 3) {
  // we've got a problem here, the URL isn't correct. Just redirect over to main site
  location.href = "https://www.skylight.org";
}
// going to assume the activityId is always the last item
const activityId = pathItems[pathItems.length - 1];
const baseUrl = window.location.origin + "/" + pathItems[1];
const activityUrl = baseUrl + "/" + activityId;
const shareUrl = activityUrl;

let videoRecIds = [activityId];

// Let's fire up mixpanel
mixpanel.init(process.env.MIXPANEL_ID, { debug: false });

window.addEventListener("DOMContentLoaded", (event) => {
  const moviecontainer = document.getElementById("videoContainer");
  const movie = moviecontainer.querySelector("video");
  const playpause = document.getElementById("playPause");
  const mute = document.getElementById("actionMute");
  const like = document.getElementById("actionLike");
  const fullscreen = document.getElementById("actionFullscreen");
  const facebookLink = document.getElementById("facebookLink");
  const twitterLink = document.getElementById("twitterLink");
  const pinterestLink = document.getElementById("pinterestLink");
  const emailLink = document.getElementById("emailLink");
  const copyLink = document.getElementById("copyLink");
  const scrubContainer = moviecontainer.querySelector(".scrub-container");
  const descriptionContainer = document.querySelector(".description");
  const descriptionTextContainer = descriptionContainer.querySelector(".description-text");
  const scrubProgress = scrubContainer.querySelector(".scrub-progress");
  const bufferProgress = scrubContainer.querySelector(".buffer-progress");

  let provider;

  let loadedSuggestedVideos = false;
  let playBegin = false;

  //For testing purposes only
  // signOut(auth).then(() => {
  //     // Sign-out successful.
  // }).catch((error) => {
  //     // An error happened.
  // });

  // add the link to the CTA button
  document.querySelector(".discover a").href = process.env.MAIN_SITE_URL;

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: activityUrl,
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'example.page.link'
  };

  // Confirm the link is a sign-in with email link.
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt("Please provide your email for confirmation");
    }
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, email, window.location.href)
      .then(async (result) => {
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser

        const providerData = result.user.providerData[0];

        await saveUser(result.user.uid, providerData).then(() => {
          addFavorite(db, result.user.uid, activityId);
        });
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        alert(error);
      });
  }

  const share = moviecontainer.querySelector(".action-share");

  const player = videojs(
    "activityVideo",
    {
      bigPlayButton: false,
      textTrackDisplay: false,
      posterImage: false,
      errorDisplay: false,
      controlBar: {
        captionsButton: false,
        chaptersButton: false,
        subtitlesButton: false,
        liveDisplay: false,
        playbackRateMenuButton: false,
      },
    },
    function () {
      //console.log(this)
    }
  );

  player.playsinline(true);
  player.preload("auto");

  player.removeChild("TextTrackDisplay");
  player.removeChild("BigPlayButton");
  player.removeChild("LiveTracker");
  player.removeChild("ControlBar");
  player.removeChild("ErrorDisplay");
  player.removeChild("TextTrackSettings");
  player.removeChild("ResizeManager");

  let video = {};

  getActivity(db, activityId);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      var uid = user.uid;
      // ...

      setFavorite(db, uid, activityId);
    } else {
      // User is signed out
      // ...
    }
  });

  const videoList = moviecontainer.querySelector(".video-list");
  removeAllChildNodes(videoList);

  scrubProgress.style.width = "0%";
  bufferProgress.style.width = "0%";

  scrubContainer.addEventListener("click", (e) => {
    e.preventDefault();
    let x = e.clientX - scrubContainer.getBoundingClientRect().left;
    let percent = x / (scrubContainer.getBoundingClientRect().right - scrubContainer.getBoundingClientRect().left);

    updateProgressWidth(percent);
    updateVideoTime(percent);
  });

  player.on("timeupdate", () => {
    videoTimeUpdateHandler();
  });

  player.on("progress", () => {
    updateBufferWidth(player.bufferedPercent());
  });

  function playVideo() {
    player.play();
    playpause.classList.add("paused");
  }

  function videoTimeUpdateHandler(event) {
    let percent = player.currentTime() / player.duration();
    updateProgressWidth(percent);

    let remaining = player.duration() - player.currentTime();

    if (remaining <= 10 && !loadedSuggestedVideos) {
      getSuggestedActivities(db);
    }
  }

  function updateProgressWidth(percent) {
    scrubProgress.style.width = percent * 100 + "%";
  }

  function updateBufferWidth(percent) {
    bufferProgress.style.width = percent * 100 + "%";
  }

  function updateVideoTime(percent) {
    player.currentTime(percent * player.duration());
  }

  playpause.addEventListener("click", (e) => {
    e.preventDefault();
    if (player.paused()) {
      player.play();
      playpause.classList.add("paused");
      playpause.classList.remove("replay");
      moviecontainer.querySelector(".video-recommendations").style.display = "none";

      if (!playBegin) {
        logEvent(analytics, "tap_exercise_begin", { name: "tap_exercise_begin", id: "y1srgs" });

        mixpanel.track("tap_exercise_begin", {
          source: document.title,
          tap_exercise_begin_exercise_id: video.id,
          tap_exercise_begin_exercise_name: video.name,
          tap_exercise_begin_exercise_tag: "activity",
        });
        playBegin = true;
      }
    } else {
      player.pause();
      playpause.classList.remove("paused");
    }
  });

  mute.addEventListener("click", (e) => {
    e.preventDefault();
    if (player.muted()) {
      player.muted(false);
      mute.classList.remove("muted");
    } else {
      player.muted(true);
      mute.classList.add("muted");
    }
  });

  fullscreen.addEventListener("click", (e) => {
    e.preventDefault();

    if (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.userAgent)
    ) {
      player.requestFullscreen();
    } else if (movie.webkitRequestFullscreen) {
      movie.webkitRequestFullscreen();
    } else {
      player.requestFullscreen();
    }
  });

  like.addEventListener("click", (e) => {
    e.preventDefault();

    if (auth.currentUser == null) {
      // The start method will wait until the DOM is loaded.
      player.pause();
      playpause.classList.remove("paused");
      document.body.classList.add("show-register");
    } else {
      const uid = auth.currentUser.uid;

      if (like.className == "action-like") {
        addFavorite(db, uid, video.id);
      } else {
        removeFavorite(db, uid, video.id);
      }
    }
  });

  const shareDialog = document.querySelector(".share-dialog");

  share.addEventListener("click", (e) => {
    e.preventDefault();
    shareDialog.querySelector(".pen-url").innerText = shareUrl;

    // hardcoded ids from iOS code
    logEvent(analytics, "tap_share_exercise", { name: "tap_share_exercise", id: "gkm9ed" });

    mixpanel.track("tap_share_exercise", {
      source: document.title,
      tap_exercise_begin_exercise_id: video.id,
      tap_exercise_begin_exercise_name: video.name,
      tap_exercise_begin_exercise_tag: "activity",
    });
  });

  // copy the URL to the clipboard
  copyLink.addEventListener("click", (event) => {
    var tempInput = document.createElement("input");
    tempInput.value = shareUrl;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  });

  // convenience method to clean out all classes related to
  // popups in one place
  function closePopups() {
    document.body.classList.remove("show-share", "show-register");
    document.querySelector(".email-register").classList.remove("show");
    document.querySelector(".email-thanks").classList.remove("show");
    document.querySelector(".resent-notice").classList.remove("sent");
  }
  // wire up elements that close popups
  document.querySelector(".close-share").addEventListener("click", closePopups);
  document.querySelector(".close-register").addEventListener("click", closePopups);
  document.querySelector(".popup-background").addEventListener("click", (e) => {
    // make sure they actually clicked on the popup background and not some
    // element inside it
    if (e.target.classList.contains("popup-background")) {
      closePopups();
    }
  });

  player.on("ended", () => {
    logEvent(analytics, "tap_exercise_begin_complete", { name: "tap_exercise_begin_complete", id: "d9f7a8" });

    mixpanel.track("tap_exercise_begin_complete", {
      // use page title for the source
      source: document.title,
      tap_exercise_begin_exercise_id: video.id,
      tap_exercise_begin_exercise_name: video.name,
      tap_exercise_begin_exercise_tag: "activity",
    });

    playpause.classList.remove("paused");
    playpause.classList.add("replay");
    moviecontainer.querySelector(".video-recommendations").style.display = "flex";
  });

  share.addEventListener("click", (event) => {
    if (navigator.share) {
      navigator
        .share({
          // removed 'title' for now
          //title: video.name,
          text: video.name,
          url: shareUrl,
        })
        .then(() => {
          console.log("Thanks for sharing! ", shareUrl);
        })
        .catch(console.error);
    } else {
      document.body.classList.add("show-share");
    }
  });

  document.addEventListener("fullscreenchange", (event) => {
    // document.fullscreenElement will point to the element that
    // is in fullscreen mode if there is one. If there isn't one,
    // the value of the property is null.
    if (document.fullscreenElement) {
      //player.controls(true);
    } else {
      //player.controls(false);
      player.play();
    }
  });

  // convenience method to add videos to the suggested activity list
  function addSuggestedActivity(videoRec) {
    videoRecIds.push(videoRec.id);
    const newDiv = document.createElement("a");
    newDiv.setAttribute("href", baseUrl + "/" + videoRec.id);

    newDiv.classList.add("video-list-item");
    newDiv.setAttribute("id", videoRec.id);

    const img = document.createElement("img");
    img.src = videoRec.imageUrl;

    const p = document.createElement("p");
    p.innerHTML = videoRec.name;

    newDiv.appendChild(img);
    newDiv.appendChild(p);

    videoList.appendChild(newDiv);
  }

  // get the suggested activities to show at the end of the video
  async function getSuggestedActivities(db) {
    loadedSuggestedVideos = true;
    const suggestedRef = doc(db, "sectionDisplays", process.env.SUGGESTED_VIDEOS_ID);
    const suggestedDoc = await getDoc(suggestedRef);
    // this is used no matter which way the selection process goes for suggested activities
    const activitiesRef = collection(db, "activities");

    // see if we have a valid document to work with
    if (suggestedDoc.exists()) {
      // we do, grab the id's from it
      const suggestedIds = [];
      suggestedDoc.data().content.forEach((item) => {
        if (item.id != activityId) {
          suggestedIds.push(item.id);
        }
      });
      // shuffle the ids
      let currentIndex = suggestedIds.length;
      let randomIndex = 0;
      // while there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [suggestedIds[currentIndex], suggestedIds[randomIndex]] = [
          suggestedIds[randomIndex],
          suggestedIds[currentIndex],
        ];
      }
      if (suggestedIds.length > 4) {
        // need to pop off the last element
        suggestedIds.pop();
      }
      // go ahead and look them up
      const q = query(activitiesRef, where("id", "in", suggestedIds));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => addSuggestedActivity(doc.data()));
    } else {
      // no suggested document, need to generate our own set

      let i = 0;

      while (i < 4) {
        const random = Math.random().toString(36).substring(2, 15);

        let q = query(activitiesRef, where("id", ">=", random), orderBy("id"), limit(1));

        let querySnapshot = await getDocs(q);
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots

            let videoRec = doc.data();

            if (i < 4 && !videoRecIds.includes(videoRec.id)) {
              addSuggestedActivity(videoRec);
              i++;
            }
          });
        } else {
          q = query(activitiesRef, where("id", "<", random), orderBy("id"), limit(1));
          querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let videoRec = doc.data();

            if (i < 4 && !videoRecIds.includes(videoRec.id)) {
              addSuggestedActivity(videoRec);
              i++;
            }
          });
        }
      }
    }
  }

  async function getActivity(db, id) {
    const docRef = doc(db, "activities", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const video = docSnap.data();
      player.src({
        src: video.trailerUrl,
        type: "application/x-mpegURL",
      });

      //const loader = descriptionContainer.querySelector(".loader");
      //loader.classList.add("hidden");

      // descriptionContainer.querySelector("img").classList.remove("hidden");
      // descriptionContainer.querySelector("img").src = video.imageUrl;
      // descriptionTextContainer.querySelector(".caption").innerText = video.name;
      // descriptionTextContainer.querySelector(".subcaption").innerText = video.description;

      //document.title = video.name + " - " + process.env.APP_TITLE;
      const escapedTitle = encodeURIComponent(video.name);
      const escapedDesc = encodeURIComponent(video.description);
      const escapedActivityUrl = encodeURIComponent(activityUrl);

      const escapedShareUrl = encodeURIComponent(shareUrl);

      //document.querySelector('meta[property="og:url"]').content = activityUrl;
      // document.querySelector('meta[property="og:title"]').content = video.name;
      // document.querySelector('meta[property="og:description"]').content = video.description;
      // document.querySelector('meta[property="og:image"]').content = video.imageUrl;

      // console.log("XXXX: ", document.querySelector('meta[property="og:title"]').content);
      // console.log("XXXX: ", document.querySelector('meta[property="og:description"]').content);
      // console.log("XXXX: ", document.querySelector('meta[property="og:image"]').content);

      facebookLink.setAttribute("href", "https://www.facebook.com/sharer/sharer.php?u=" + escapedShareUrl);
      twitterLink.setAttribute(
        "href",
        "https://twitter.com/intent/tweet?url=" + escapedShareUrl + "&text=" + escapedDesc
      );
      pinterestLink.setAttribute(
        "href",
        "https://pinterest.com/pin/create/button/?url=" + escapedShareUrl + "&media=&description=" + escapedDesc
      );
      emailLink.setAttribute(
        "href",
        "mailto:?&subject=Skylight%20-%20" +
          escapedTitle +
          "&cc=&bcc=&body=Check%20out%20this%20video%20I%20found%20on%20Skylight%20" +
          escapedTitle +
          ":%20" +
          escapedDesc +
          "%20" +
          escapedShareUrl
      );
      document.getElementById("penUrl").innerHTML = shareUrl;
    } else {
      location.href = "https://www.skylight.org";
    }
  }

  async function addFavorite(db, id, video_id) {
    const userRef = doc(db, "users", id);
    const userSnap = await getDoc(userRef);

    like.classList.add("like-loader");

    if (userSnap.exists()) {
      await updateDoc(userRef, {
        favoriteActivityIds: arrayUnion(video_id),
      })
        .then(() => {
          like.classList.remove("like-loader");
          like.classList.add("action-like");
          like.classList.add("liked");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          like.classList.remove("like-loader");
          console.error("Error updating document: ", error);
        });
    } else {
      alert("Not a Skylight user.");
    }
  }

  async function setFavorite(db, id, video_id) {
    const userRef = doc(db, "users", id);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      let favoriteActivityIds = userSnap.data().favoriteActivityIds;

      if (favoriteActivityIds.includes(video_id)) {
        like.classList.add("liked");
      }
    }
  }

  async function removeFavorite(db, id, video_id) {
    const userRef = doc(db, "users", id);
    const userSnap = await getDoc(userRef);

    like.classList.add("like-loader");

    if (userSnap.exists()) {
      await updateDoc(userRef, {
        favoriteActivityIds: arrayRemove(video_id),
      })
        .then(() => {
          like.classList.remove("like-loader");
          like.classList.add("action-like");
          like.classList.remove("liked");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          like.classList.remove("like-loader");
          console.error("Error updating document: ", error);
        });
    } else {
      alert("Not a Skylight user!");
    }
    s;
  }

  function removeAllChildNodes(parent) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  function signInWithProvider(providerName) {
    if (providerName == "google") {
      provider = new GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
    } else {
      provider = new OAuthProvider("apple.com");
      provider.addScope("email");
      provider.addScope("name");
    }

    signInWithPopup(auth, provider)
      .then(async (result) => {
        if (provider == "google") {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // ...
        } else {
          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;
        }

        // The signed-in user info.
        const user = result.user;

        const providerData = result.user.providerData[0];

        await saveUser(result.user.uid, providerData).then(() => {
          addFavorite(db, result.user.uid, activityId);
          closePopups();
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        closePopups();

        alert(errorMessage);
      });
  }

  async function saveUser(id, providerData) {
    const userRef = doc(db, "users", id);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      await setDoc(doc(db, "users", id), {
        id: id,
        email: providerData.email,
        name: providerData.displayName,
        photoURL: providerData.photoURL,
        phone: providerData.phoneNumber,
      });
    } else {
      await updateDoc(userRef, {
        email: providerData.email,
        name: providerData.displayName,
        photoURL: providerData.photoURL,
        phone: providerData.phoneNumber,
      });
    }
  }

  document.getElementById("google-btn").addEventListener("click", (e) => {
    signInWithProvider("google");
  });

  if (process.env.APPLE_LOGIN_ENABLED == "true") {
    document.getElementById("apple-btn").addEventListener("click", (e) => {
      signInWithProvider("apple");
    });
  } else {
    document.getElementById("apple-btn").remove();
  }

  document.getElementById("email-btn").addEventListener("click", (e) => {
    // show the email form subscreen
    document.querySelector(".email-register").classList.add("show");
  });

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    //alert("You have entered an invalid email address!")
    return false;
  };

  const submitEmail = () => {
    const email = document.getElementById("email-address").value;
    const cleanEmail = email.replace(/<\/?[^>]+(>|$)/g, "");
    document.querySelectorAll(".email-field").forEach((field) => {
      field.innerHTML = cleanEmail;
    });

    if (validateEmail(cleanEmail)) {
      document.querySelector(".email-thanks").classList.add("show");
      signInWithEmailLinkAuth(cleanEmail);
    } else {
      const emailError = document.querySelector(".email-error");
      emailError.innerHTML = "Enter a valid email.";
    }
  };
  document.getElementById("email-address").addEventListener("keyup", (e) => {
    if (e.key == "Enter") {
      submitEmail();
    }
  });
  document.getElementById("submit-email-btn").addEventListener("click", submitEmail);

  document.getElementById("resend-email-btn").addEventListener("click", (e) => {
    signInWithEmailLinkAuth(window.localStorage.getItem("emailForSignIn"));
    document.querySelector(".resent-notice").classList.add("sent");
  });

  function signInWithEmailLinkAuth(email) {
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
        alert(errorMessage);
      });
  }

  // wire up the back buttons
  document.querySelectorAll(".back-btn").forEach((backBtn) => {
    backBtn.addEventListener("click", (e) => {
      backBtn.parentElement.parentElement.classList.remove("show");
    });
  });
});
